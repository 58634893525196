.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: -6px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 14px !important;
		padding: .7rem 0;
		color: $colorSecondary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: $gray1 !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px lighten($gray4, 10);
		border-radius: .3rem;
		padding:  .2rem 1rem;
		text-align: left;
		transition: all .2s ease-in-out;
		&:hover{
			border-color: $gray4;
		}
	}
	& .component {
		border: 1px solid lighten($gray4, 10) !important;
		z-index: 10;
		& .items{
			overflow: auto;
			& .group{
				color: $gray2 !important;
			}
			
			& .group_itens{
			}
		}


		& .botoes {
			padding: .5rem;

			& .undo{
				background-color: transparent;
				text-transform: uppercase;
				border: solid 1px lighten($gray4, 10);
				color: $colorTerciary;
				font-weight: 600;
				font-size: .7rem;
				cursor: pointer;
				border-radius: .2rem;
				padding: .5rem 0;
				transition: all .2s ease-in-out;
				
				&:hover{
					background-color: lighten($gray4, 15);
				}
			}

			& .ok{
				background-color: $colorSecondary;
				text-transform: uppercase;
				border: solid 1px $colorSecondary;
				color: white;
				font-weight: 600;
				font-size: .7rem;
				cursor: pointer;
				border-radius: .2rem;
				padding: .5rem 0;
				transition: all .2s ease-in-out;

				&:hover{
					background-color: darken($colorSecondary,5);
				}
			}

			& button {
				display: block;
				margin: 0;
				outline: none;
				border: 0;
				background-color: transparent;
				height: inherit;
			}
		}
	}

}
