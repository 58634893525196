.miniatura_depoimento{
    display: block;
    width: 100%;
    border: solid 1px darken($gray5, 5);
    border-radius: 1rem;
    padding: 1rem;

    & .nome{
        font-size: 1.2rem;
        font-weight: 600;
        color: $colorTerciary;
        margin: 0;
    }

    & .cargo{
        font-size: .85rem;
        font-weight: 400;
        color: $gray2;
        margin: 0;
    }

    & .depoimento{
        font-size: .9rem;
        font-weight: 400;
        color: $gray2;
        min-height: 3.7rem;
        max-height: 3.7rem;
        overflow: hidden;
    }

    & .vermais_btn{
        display: block;
        width: fit-content;
        margin-right: 0;
        
        & .vermais{
            font-size: .85rem;
            font-weight: 500;
            color: $colorPrimary;
            user-select: none;
            cursor: pointer;
            text-align: right;
            transition: all .2s ease-in-out;

            & i{
                color: transparent;
                font-weight: 800;
                transition: all .2s ease-in-out;
            }

            &:hover{
                color: darken($colorPrimary, 5);
                & i{
                    color: $colorTerciary;
                }
            }
        }
    }

    & .data{
        font-size: .8rem;
        font-weight: 400;
        color: $gray2;
        margin: 0;
    }
}

.nenhum_depoimento{
    display: block;
    width: 100%;

    & h1{
        text-align: center;
        font-size: 1.3rem;
        font-weight: 500;
        color: $gray1;
    }

    & a{
        margin: auto;
        width: fit-content;
        display: block;
        & p{
            background-color: $colorTerciary;
            color: white;
            font-size: 1rem;
            font-weight: 400;
            padding: .5rem 1rem;
            border-radius: 2rem;
            transition: all .2s ease-in-out;
            &:hover{
                background-color: $colorPrimary;
            }
        }
    }
}