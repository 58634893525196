.nav {
	display: block;
	width: 20vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99999999;
	padding: 0 1rem;
	box-shadow: 0px 0 20px 1px rgba($gray1, .1);
	text-align: right;

	& button {
		margin-right: 0;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: $colorTerciary;
		font-size: 1.3rem;
		font-weight: 600;
		padding: 0 1rem;
		text-align: left;
		margin-bottom: 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: $colorTerciary;
		font-size: 1rem;
		margin: 0;
		padding: .5rem 1rem;
		@include border-box;
		border-left: solid 2px transparent;
		transition: all .25s ease-in-out;

		&:hover {
			padding-left: 1.5rem;
			border-left: solid 2px $colorSecondary;
		}
	}
	& .redes_sociais_navmenu{
		display: block;
		width: 100%;
		padding: 1rem 0;
		& a{
			display: inline-block;
			width: 15%;
			vertical-align: middle;
			& i{
				font-size: 2rem;
				font-weight: 500;
				padding: .5rem;
				border: solid 2px $colorSecondary;
				border-radius: .1rem;
				color: $colorPrimary;
				transition: all .15s;
				&:hover{
					transform: scale(1.1);
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.nav {
		width: 25vw;
	}
}

@media (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		
	}
}