.section_imobiliaria {
	display: block;
	width: 100%;

	& h1 {
		font-size: 2rem;
		font-weight: 600;
		color: $colorPrimary;
	}

	& h2 {
		font-size: 2rem;
		font-weight: 600;
		color: $colorPrimary;
	}

	& p {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
	}

	& strong {
		color: $colorPrimary;
	}
}

.nadaEncontradoImobiliaria {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& p {
		font-size: 1rem;
		font-weight: 400;
		color: $gray1;
		text-align: center;
		max-width: 50vw;
		line-height: 2rem;

		& span {
			font-weight: 500;
			font-style: italic;
			color: $colorTerciary;
		}
	}

	& a {
		width: fit-content;
		display: block;

		& p {
			background-color: $colorTerciary;
			color: white;
			padding: .5rem 1.5rem;
			border-radius: .3rem;
			transition: background-color .2s ease-in-out;
			line-height: normal;
			font-weight: 500;

			&:hover {
				background-color: lighten($colorTerciary, 5);
			}
		}
	}
}

@media (max-width: 1023px) {
	.nadaEncontradoImobiliaria {
		& p {
			max-width: 100%;
			line-height: 2rem;
			font-size: 1.1rem;
		}
	}
}