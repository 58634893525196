.header1{
	background-color: transparent;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	box-shadow: 0 0 10px 1px rgba(black, .1);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: white;
	width: 100%;
	z-index: 9999;

	& p{
		color: $gray1 !important;
	}

	& a{
		color: $gray1 !important;
		&:hover{
			color: $colorPrimary !important;
		}
	}
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
	margin: 0%;
}

#sub_menu_vendas{
	display: none;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.5rem;
	transform: translate(-50%);
	background-color: white;
	border-radius: .3rem;
	border: solid 1px lighten($gray4, 10);
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		padding: .5rem;

		& p{
			font-size: .85rem !important;
			font-weight: 400;
			line-height: normal;
			background-color: none;
			padding: .75rem 0.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s ease-in-out;
			color: $gray1;
			border-left: solid 1px transparent;

			&:hover{
				background-color: $gray5;
				padding-left: 1rem;
				border-color: $colorSecondary;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	background-color: white;

	& .superior {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		width: 100%;

		/* GRUPO HEADER */
		& .logo {
			display: inline-block;
			@include border-box;
			vertical-align: middle;
			width: fit-content;
			margin: 0;
			padding: 0;
			
			& .img{
				width: calc(350px / 1.75);
				height: calc(200px / 1.75);
				display: block;
				
				& img {
					display: block;
					width: calc(350px / 1.75);
					height: calc(200px / 1.75);
				}
			}
		}

		& .sessions {
			display: block;
			width: 100%;
			margin: 0;

			& ul {
				display: flex;
				align-items: center;
				margin: 0;
				justify-content: space-around;

				& .itens_header{
					display: inline-block;
					padding: 0;

					& p{
						font-size: .9rem;
						color:  $colorTerciary;
						text-align: center;
						font-weight: 400;
						user-select: none;
					}


					& .atend{
						font-size: .8rem !important;
						margin: 0;
					}

					& a{
						color: $colorTerciary;
						font-weight: 400;
						font-size: .9rem;
						transition: all .2s ease-in-out;
						text-transform: initial;
						user-select: none;

						& i{
							transition: all .1s ease-in-out;
							font-size: 1.3rem;
							color: $colorSecondary !important;
						}

						& .hover_scale{
							&:hover{
								transform: scale(1.1);
							}
						}

						& .wpp_headerr{
							font-weight: 600;
							font-size: .9rem;
							margin: 0;
							transition: all .2s ease-in-out;
							&:hover{
								color: $colorPrimary;
							}
						}

						&:hover{
							color: $colorPrimary;
							
						}
					}

					& .itemsession{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
	
							& .itemsession_title{
								vertical-align: middle;
								display: inline-block;
								width: 100%;
								font-size: .9rem;
								color:  $colorTerciary;
								text-align: center;
								font-weight: 400;
								padding: 0 1rem;
								@include border-box;
								transition: all .2s;
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  $colorSecondary;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas {
								display: initial;
							}
						}
						
					}
				}
				
				& .itens_header2{
					& .itemsession{
						position: relative;
						display: block;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
							padding: .5rem 0;
	
							& .itemsession_title{
								display: block;
								width: 100%;
								font-size: .9rem;
								color: $gray1;
								text-align: center;
								text-transform: initial;
								font-weight: 400;
								padding: 0;
								@include border-box;
								transition: all .2s ease-in-out;
								line-height: 1rem;
								
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  $colorSecondary;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas{
								display: initial;
							}
						}
					}
				}
			}
		}

		& .menu {
			display: flex;
			justify-content: center;
			align-items: center;
			width: fit-content;
		}
	}
}

.headerindex {
	height: calc(100vh - 7.1rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, .2);
			//background: linear-gradient(to bottom, rgba(black, .5) 0%, transparent 35%);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg_header1.webp');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 0%;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1024px) and (max-width: 1366px){

	.headerindex {
		height: calc(100vh - 6.3rem);
		
		& .container-bg-headerpc{
			& .overlay_bg_home{
				background: linear-gradient(to bottom, rgba(black, .5) 0%, transparent 40%);
			}
		}
	}

	.headerpc {
		& .superior {
			/* GRUPO HEADER */
			& .logo {
				& .img{
					width: calc(350px / 2);
					height: calc(200px / 2);
					
					& img {
						width: calc(350px / 2);
						height: calc(200px / 2);
					}
				}
			}

			& .sessions {
				& ul {
					& .itens_header{
						& a{
							font-size: .9rem;

							& i{
								font-size: 1.1rem;
							}
						}
	
						& .itemsession{
							& .title_is{
								& .itemsession_title{
									font-size: .9rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: fit-content;
		background-color: $gray5;
		
		& .container-bg-headerpc{
			display: none;
		}
	}
}
