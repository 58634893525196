.title_section_imoveis{
	text-align: center;
	& h1{
		font-size: 2rem;
		font-weight: 600;
		color: $colorPrimary;
	}
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorPrimary;
		margin: 0;

		& i {
			color: $colorSecondary;
			font-size: 1.2rem;
			vertical-align: middle;
		}
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorSecondary;
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: white;
		margin-top: 0;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.section_servicos {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	& .title_servicos {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: .5rem;

		& h2 {
			font-size: 1.75rem;
			font-weight: 600;
			margin: 0;
			color: $colorPrimary;
		}

		& p {
			color: $gray2;
			font-size: 1.1rem;
			font-weight: 500;
			margin: 0;

			& i {
				color: $colorSecondary;
				font-size: .8rem;
				vertical-align: middle;
			}
		}
	}

	& .container_servicos {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 90%;
		gap: 2rem;

		& .bloco_servico {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: solid 1px lighten($gray4, 10);
			border-radius: .5rem;

			& .top_servicos {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				gap: 1.5rem;
				padding: 2rem;

				& .img {
					width: calc(512px / 8);
					height: calc(512px / 8);
					margin: 0 auto;
					background: linear-gradient(to right, $colorSecondary, darken($colorSecondary, 20%));
					padding: .5rem;
					box-sizing: border-box;
					position: relative;
					border-radius: .25rem;
	
					& img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: calc(512px / 10);
						height: calc(512px / 10);
						display: block;
					}
				}
	
				& .descricao {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					gap: .5rem;
	
					& h2 {
						font-weight: 600;
						font-size: 1.1rem;
						color: $colorPrimary;
						margin: 0;
						text-align: center;
					}
	
					& p {
						color: $colorPrimary;
						font-size: .85rem;
						font-weight: 400;
						text-align: center;
					}
				}
			}

			& .contato_ver_mais {
				border-top: solid 1px lighten($gray4, 10);
				display: block;
				width: 100%;

				& a {
					
					& p {
						text-align: center;
						font-size: 1rem;
						font-weight: 500;
						color: $colorPrimary;
						margin: 0;
						padding: 1.5rem 0;
						transition: all .2s ease-in-out;
						border-radius: 0 0 .3rem .3rem;

						& i {
							font-size: .8rem;
							font-weight: 800;
							vertical-align: middle;
							color: $colorSecondary;
							transition: all .2s ease-in-out;
						}

						&:hover{
							background-color: darken($colorSecondary, 5);
							color: white;

							& i {
								color: white;
							}
						}
					}
				}
			}
		}
	}
}

.section_sobre {
	background: linear-gradient(135deg, white 65%, lighten($colorPrimary, 5) 65%);
	background-color: white;
	display: block;
	width: 100%;

	& .container_sobre {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 2rem;
		align-items: center;

		& .conteudo {
			width: 80%;
			margin: 0 auto;

			& h3 {
				font-size: 1.3rem;
				font-weight: 600;
				color: $colorSecondary;
				margin: 0;
			}

			& h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: $colorPrimary;
			}

			& p {
				font-size: 1rem;
				font-weight: 400;
				color: $gray2;
			}

			& .btn_vermais {
				width: 100%;
				margin: 0;

				& a {
					width: fit-content;
					display: block;
					margin: 0;

					& p {
						background-color: white;
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 600;
						padding: .3rem 1.5rem;
						border-radius: .3rem;
						border: solid 1px lighten($gray4, 10);
						transition: all .2s ease-in-out;

						& i {
							color: $colorSecondary;
							font-size: 1.2rem;
							vertical-align: middle;
							transition: all .2s ease-in-out;
						}

						&:hover {
							border-color: $colorSecondary;
							background-color: $colorSecondary;
							color: white;

							& i {
								color: white;
							}

						}
					}
				}
			}

			& .infos_sobre {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem 3rem;

				& .info_imob {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					& .img {
						width: calc(256px / 6.5);
						height: calc(256px / 6.5);
						display: inline-block;
						vertical-align: middle;
						background: linear-gradient(to right, $colorSecondary, darken($colorSecondary, 20%));
						padding: 1.5rem;
						box-sizing: border-box;
						position: relative;
						border-radius: .3rem;

						& img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: calc(256px / 6.5);
							height: calc(256px / 6.5);
						}
					}

					& .descricao {
						width: 100%;

						& .title {
							margin: 0;
							font-size: .9rem;
							color: $gray1;
							font-weight: 600;
							margin: 0;
						}

						& p {
							padding-left: 1rem;
							color: $gray2;
							font-size: .85rem;
							font-weight: 400;
							margin: 0;
						}

						& a {
							width: fit-content;
							display: block;
							margin: 0;

							& p {
								color: $gray2;
								font-size: .85rem;
								font-weight: 400;
								margin: 0;
								background-color: transparent;
							}
						}
					}
				}
			}
		}

		& .imagem_sobre {
			width: 100%;
			margin: 0;

			& img {
				border-radius: 10%;
				width: calc(1024px / 2);
				height: calc(1024px / 2);
				/* clip-path: url(#marker); */
				-webkit-user-drag: none;
			}
		}
	}
}

.sectionNewsletter {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: solid 2px $colorPrimary;

	& .containerNewsletter {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;

		& form {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			width: 100%;

			& .itemFormNL {
				flex-basis: 20%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin: 0;

				& .formulario-titulo {
					margin: 0;
					line-height: 1.5rem;
				}

				& input {
					width: 100%;
				}

				& button {
					margin: 0;
				}
			}
		}
	}
}

.bg_busca_interna {
	background-color: white;
	display: block;
	width: 100%;

	& h1 {
		text-align: center;
		color: $colorPrimary;
		font-size: 1.8rem;
		font-weight: 600;
		margin: 0;
	}


	& .container_filtros_busca_interna {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2.5rem 1rem;

		& .grid_items {
			width: 100%;

			& .titulo-formulario {
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

.b_submit_email{
	width: 100%;
	border: none;
	padding: .5rem 0;
	background-color: $colorTerciary;
	color: white;
	text-transform: uppercase;
	border-radius: 2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease;
	&:hover{
		background-color: darken($colorTerciary, 2);
	}
}

.botao_voltar{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;
	padding: .3rem 1rem;
	color: $gray1 !important;
	font-size: .8rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;

	& i {
		color: $colorSecondary;
	}

	&:hover {
		border-color: $gray4;
	}
}

.botao_voltar_interna{
	background-color: $colorPrimary;
	border: none;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: $colorSecondary !important;
	font-size: .8rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.title_contato {
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorPrimary;
	vertical-align: middle;
	margin: 0;

	& i {
		font-size: 1.2rem;
		color: white;
		background: linear-gradient(to right, $colorSecondary, darken($colorSecondary, 20%));
		padding: .25rem;
		border-radius: .2rem;
	}
}

@media (min-width: 1024px) and (max-width: 1366px) {

	.title_contato {
		font-size: 1.25rem;
	}

	.bg_busca_interna {
		& h1 {
			font-size: 1.4rem;
		}
	}

	.section_servicos {
		& .container_servicos {
			& .bloco_servico {
				& .top_servicos {
					padding: 2rem 1.5rem;

					& .img {
						width: calc(512px / 9);
						height: calc(512px / 9);

						& img {
							width: calc(512px / 12);
							height: calc(512px / 12);
						}
					}
				}
	
				& .contato_ver_mais {
					& a {
						& p {
							font-size: .9rem;
	
							& i {
								font-size: .8rem;
							}
						}
					}
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(135deg, white 65%, lighten($colorPrimary, 5) 65%);

		& .container_sobre {
			gap: 2rem;
			

			& .conteudo {
				width: 85%;

				& p {
					font-size: .9rem;
				}

				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;

							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					gap: 2rem;

					& .info_imob {

						& .img {
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);

							& img {
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}

						& .descricao {
							& p {
								font-size: .85rem;
							}

							& a {
								& p {
									font-size: .85rem;
								}
							}
						}
					}
				}
			}

			& .imagem_sobre {
				& img {
					width: calc(1024px / 2.25);
					height: calc(1024px / 2.25);
				}
			}
		}
	}
}

@media (max-width: 1023px){
	.title_contato {
		font-size: 1.1rem;
	}

	.title_section_imoveis{
		& h2{
			font-size: 1.5rem;
		}
	}

	.bg_busca_interna {
		& h1 {
			font-size: 1.4rem;
		}

		& .container_filtros_busca_interna {
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}
	
	.blog_section{
		width: 100%;
		display: block;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.section_servicos{
		& .bloco_qrcode{
			width: 100%;
			background-color: white;
			border: solid 1px transparent;
			border-radius: .2rem;
			height: 20rem;
			padding: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 0 20px 0 rgba(black, .1);
			
			& .content_serv{
				display: block;
				width: fit-content;
				max-width: 30rem;
				margin: auto;
				z-index: 2;
				text-align: center;
	
				& img{
					width: 65%;
					display: inline-block;
					vertical-align: middle;
					margin: auto;
				}
	
				& .wpp_ven_loc{
					margin: 0;
					font-size: 1.1rem;
					font-weight: 600;
				}
	
				& hr{
					margin-top: 1rem;
					margin-bottom: 1rem;
					height: .06rem;
					background-color: $gray4;
				}
	
				& p{
					color: $colorPrimary;
					font-size: .9rem;
					font-weight: 400;
					text-align: center;	
					padding: 0;
					margin: 0;
				}
			}
		}
	}

	.section_servicos {
		& .title_servicos {
	
			& h2 {
				font-size: 1.5rem;
			}
	
			& p {
				font-size: 1rem;
	
				& i {
					font-size: .7rem;
				}
			}
		}
	
		& .container_servicos {
			flex-direction: column;
			width: 100%;
			gap: 3rem;
	
			& .bloco_servico {
				& .top_servicos {
					& .img {
						width: calc(512px / 9);
						height: calc(512px / 9);
		
						& img {
							width: calc(512px / 12);
							height: calc(512px / 12);
						}
					}
		
					& .descricao {
						& h2 {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(180deg, $colorPrimary 20%, white 20%);

		& .container_sobre {
			display: flex;
			flex-direction: column;
			flex-direction: column-reverse;
			gap: 1rem;
			grid-template-columns: none;


			& .conteudo {
				width: 100%;

				& h3 {
					font-size: 1.2rem;
				}

				& h2 {
					font-size: 1.3rem;
				}

				& p {
					font-size: .9rem;
				}

				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;

							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					grid-template-columns: 1fr;
					gap: 2rem;

					& .info_imob {

						& .img {
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);

							& img {
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}

						& .descricao {
							& p {
								font-size: .85rem;
							}

							& a {
								& p {
									font-size: .85rem;
								}
							}
						}
					}
				}
			}

			& .imagem_sobre {
				text-align: center;

				& img {
					width: calc(1024px / 3.25);
					height: calc(1024px / 3.25);
					max-width: 90vw;
					margin: auto;
				}
			}
		}
	}

	.sectionNewsletter {
		& .containerNewsletter {
			& form {
				flex-direction: column;
	
				& .itemFormNL {
					width: 100%;
					flex-direction: column;
				}
			}
		}
	}
}