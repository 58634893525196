/* SCROLL-BAR */
::-webkit-scrollbar{width: 5px; height: 10px;}
::-webkit-scrollbar-track-piece{background-color: $gray5;}
::-webkit-scrollbar-thumb:vertical{height: 5px; background-color: $gray3;}
::-webkit-scrollbar-thumb:horizontal{width: 5px; background-color: $gray3;}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.ho-title{
	font-size: 1.3rem;
}

.ho-subtitle{
	font-size: 1.1rem;
}

.ho-txt{
	font-size: 1rem;
}

.ho-mini{
	font-size: 0.9rem;
	margin-top: 3px;
    margin-bottom: 3px;
}

.font-style {
	font-family: 'Open Sans';
	font-size: 2.4rem;
}

.bg_section_imoveis{
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& .containerMiniaturasIndex {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		& .miniatura-imovel {
			width: 100%;
		}
	}
}

.btn_ver_mais{
	text-align: center;
	width: fit-content;

	& p{
		color: $gray1;
		background-color: white;
		border: solid 1px lighten($gray4, 10);
		font-weight: 600;
		font-size: 1rem;
		transition: all .2s ease-in-out;
		padding: .3rem 1rem;
		border-radius: .3rem;
		margin: 0;

		& i {
			color: $colorSecondary;
			transition: all .2s ease-in-out;
		}
		
		&:hover{
			background-color: $colorSecondary;
			color: white;

			& i {
				color: white;
			}
		}
	}
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'Open Sans', sans-serif;
	background-color: white;
	color: $gray2;
	font-weight: 500;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

.u-title{
	padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 3px solid $colorPrimary;
}

.politica_formularios{
	width: 100%;
	display: block;
	
	& p{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
		& a{
			color: $colorTerciary;
			font-weight: 500;
			font-size: .8rem;
			user-select: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	& label{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
	}
	
	& input {
		width: fit-content !important;
	}
}

.btn_enviar_form{
	width: 100%;
	display: block;
	background-color: $colorSecondary;
	color: white;
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	border: solid 1px $colorSecondary;
	border-radius: .3rem;
	cursor: pointer;
	transition: all .2s ease-in-out;
	padding: .5rem 2rem;
	&:hover{
		background-color: darken($colorSecondary, 5);
	}
}

h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $gray2;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1;

	&:hover {
		color: white;
	}
	&:visited, &:focus, &:active{
		color: $gray1;
	}
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 400;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.icon-gradiente {
	@include gradient('left, #{$colorSecondary}, #{darken($colorSecondary, 20%)}');
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.container-fixo {
	position: fixed;
	bottom: 80px;
	right: 20px;
	z-index: 9999;

	& #ux_to_top{
		display: block;
		z-index: 9999;
		margin: auto;
	}

	& a{
		display: block;
		width: 60px;
		height: 60px;
		border: none;
		border-radius: 50%;
		background: #00af53;
		cursor: pointer;
		box-shadow: 0 0 0 0 rgba(0, 175, 83, 0.5);
		transition: all .2s;
		position: relative;

		& p{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			& i{
				font-size: 1.8rem;
				color: white;
			}
		}
	}
}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}
.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorSecondary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.creccss {
	margin: 0;

	& select {
		text-align: center;
		color: $gray2 !important;
		border: solid 1px lighten($gray4, 10);
		border-radius: .3rem;
		font-size: .9rem;
		font-weight: 500;
		width: 100%;
		height: auto;
		padding: .5rem 0;
		background-color: white;
		cursor: pointer;
		transition: border-color .2s ease-in-out;
		box-sizing: border-box;
		margin: 0;

		&:hover {
			border-color: $gray4;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){
	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){
	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){

	html{
		font-size: 3.75vw;
	}
}

@media (max-width:1023px){

	.container-fixo {
		position: fixed !important;
		bottom: 90px !important;
		right: 20px !important;
		z-index: 9997;
	}

	.font-style {
		font-size: 1.7rem;
		color: white;
		padding-top: 1rem;
	}

	.bg_section_imoveis{
		& .containerMiniaturasIndex {
			grid-template-columns: 1fr;
	
			& .miniatura-imovel {
				width: 100%;
			}
		}
	}
}
