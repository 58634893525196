.footer1{
	border-top: solid 2px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $colorPrimary;

	& .containerTopFooter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		gap: 1rem;
		
		& .logo_footer{
			display: block;
			width: 100%;
			margin: 0;

			& a{
				display: block;
				width: fit-content;

				& .img{
					display: block;
					width: calc(350px / 1.35);
					height: calc(200px / 1.35);
					margin: 0;

					& img{
						width: calc(350px / 1.35);
						height: calc(200px / 1.35);
					}
				}
			}
		}

		& .contatosFooter {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 2.5rem;
			margin: 0;

			& .rs_footer{
				display: block;
				width: 100%;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: white;
					margin: 0 0 .75rem 0;
				}

				& .boxRS {
					display: flex;					
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					gap: 1.5rem;
					width: 100%;

					& a{
						margin: 0;
						display: block;
						width: fit-content;						

						& p{
							margin: 0;
							padding: 0;

							& i{
								color: white;
							}
						}
					}
				}
			}
			
			& .tel_email_footer{
				display: block;
				width: 100%;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: white;
					margin: 0 0 .75rem 0;
				}
				
				& .boxContatos {
					display: flex;					
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 1rem;
					width: 100%;

					& a{
						display: block;
						width: fit-content;

						& p{
							font-size: .8rem;
							font-weight: 400;
							color: white;
							margin: 0;
						}
					}
				}
			}
		}

		& .menu_footer{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;
			width: 100%;
			margin: 0;
	
			& a{
				display: block;
				width: fit-content;
				margin: 0;
	
				& p{
					font-size: .9rem;
					font-weight: 600;
					color: white;
					margin: 0;
				}
			}
		}

		& .endLocFooter {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 2.5rem;
			width: 100%;
			margin: 0;
			
			& .endereco_footer{
				display: block;
				width: fit-content;
				margin: 0;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: white;
					margin: 0 0 .75rem 0;
				}
		
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: white;
					margin: 0;
					width: 100%;
				}
			}
		
			& .horarioatendimento_footer{
				display: block;
				width: 100%;
		
				& h3{
					font-size: .9rem;
					font-weight: 600;
					color: white;
					margin: 0 0 .75rem 0;
				}
		
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: white;
				}
			}
		}
	}
	
	& .containerBottomFooter {
		width: 100%;
		display: block;

		& .ItemContainerInferior {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;

			& p {
				margin: 0;
				font-size: .8rem;
				font-weight: 400;
				color: white;
				user-select: none;

				& a {
					& span {
						color: white;
						font-weight: 600;
						font-size: .8rem;
						transition: color .2s ease-in-out;
					}

					&:hover {
						& span {
							color: $colorSecondary;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.footer1{
		& .containerTopFooter {
			& .logo_footer{
				& a{
					& .img{
						width: calc(350px / 1.5);
						height: calc(200px / 1.5);
	
						& img{
							width: calc(350px / 1.5);
							height: calc(200px / 1.5);
						}
					}
				}
			}
	
			& .contatosFooter {
				& .rs_footer{
					& h3{
						font-size: .85rem;
					}
	
					& .boxRS {
						gap: 1.25rem;
					}
				}
				
				& .tel_email_footer{
					& h3{
						font-size: .85rem;
					}
				}
			}
	
			& .menu_footer{
				& a{
					& p{
						font-size: .85rem;
					}
				}
			}
	
			& .endLocFooter {
				& .endereco_footer{
					& h3{
						font-size: .85rem;
					}
				}
			
				& .horarioatendimento_footer{
					& h3{
						font-size: .85rem;
					}
				}
			}
		}
	}
}

@media (max-width: 1023px){
	.footer1{
		& .containerIndicesFooter {
			grid-template-columns: repeat(2, 1fr);
			gap: 2.5rem 1.5rem;
	
			& div {
				& .ind_estemes {
					font-size: .85rem;
				}
		
				& .ind_att {
					font-size: .75rem;
				}
			}
		}
	
		& .containerTopFooter {
			flex-direction: column;
			gap: 3rem;
			
			& .logo_footer{
				& a{
					margin: auto;
	
					& .img{
						width: calc(350px / 1.5);
						height: calc(200px / 1.5);
	
						& img{
							width: calc(350px / 1.5);
							height: calc(200px / 1.5);
						}
					}
				}
			}
	
			& .contatosFooter {
				& .rs_footer{

					& h3 {
						margin: 0 0 1rem 0;
					}

					& .boxRS {
						gap: 2rem;
					}
				}
				
				& .tel_email_footer{
					& h3{
						margin: 0 0 1rem 0;
					}
				}
			}
	
			& .endLocFooter {
				& .endereco_footer{
					& h3{
						margin: 0 0 1rem 0;
					}

					& p {
						line-height: 1.5rem;
					}
				}
			
				& .horarioatendimento_footer{
					& h3{
						margin: 0 0 1rem 0;
					}
				}
			}
		}
		
		& .containerBottomFooter {
			& .ItemContainerInferior {
				& p {
					text-align: center;
					line-height: 1.5rem;					
				}
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}