.header-mob{
	background-color: transparent;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 .75rem;
	border-top: solid 2px $gray4;

	& .box_logo_mobile{
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;

		& .log{
			display: block;
			width: calc(350px / 2.5);
			height: calc(200px / 2.5);
			margin: 0;
			
			& img{
				width: calc(350px / 2.5);
				height: calc(200px / 2.5);
			}
		}
	}

	& .box_btn_menu_mobile{
		width: fit-content;
		display: block;
		margin: 0;

		& .btn-men{
			display: block;
			width: 100%;
		}
	}
}