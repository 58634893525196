.imo_title {
	display: block;
	width: 100%;

	& h1 {
		font-size: 1.3rem;
		font-weight: 500;
		color: $colorPrimary;
		margin-top: 0;
	}

	& p {
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;

		& i {
			padding: .1rem;
			font-size: .8rem;
		}
	}
}

.imovel_info_section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	& .item_imovel_section {
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		text-align: center;
		border-radius: .3rem;

		& p {
			text-align: center;
			font-size: .9rem;
			font-weight: 400;
			color: $gray1;
		}

		& .share_print_block {
			display: flex;
			width: 100%;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 1rem;

			& button {
				border: none;
				background-color: transparent;
				cursor: pointer;
			}
		}
	}
}

.btn_entrar_em_contato_mobile {
	display: block;
	width: 100%;

	& button {
		width: 100%;
		padding: .7rem 0;
		border: solid 1px $colorSecondary;
		background-color: $colorSecondary;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: .3rem;

		& i {
			font-weight: 600;
		}

		&:active {
			background-color: darken($colorSecondary, 5);
		}
	}
}

.section_troca_promocao {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	.box_promocao_troca {
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		border-radius: .3rem;
		padding: .5rem 1rem;

		& .title {
			font-size: .9rem;
			font-weight: 600;
			color: $gray1;
			margin: 0 0 .5rem 0;

			& i {
				color: $colorSecondary;
			}
		}

		& p {
			font-size: .8rem;
			line-height: 1.3rem;
			font-weight: 400;
			color: $gray2;
			margin: 0;
		}
	}
}

.formulario_interno {
	background-color: white;
	padding: 1rem;
	border-radius: .3rem;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);

	& h4 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
		margin: 0;
	}

	& .btn_zap_interno {
		width: fit-content;
		display: block;
		padding: 0 2rem;
		background-color: #00af53;
		margin: auto;
		border-radius: 2rem;
		cursor: pointer;
		transition: background-color .2s ease-in-out;

		& p {
			text-align: center;
			color: white;
			font-size: 1.1rem;
			font-weight: 600;
			padding: .5rem 0;
			vertical-align: middle;
			user-select: none;

			& i {
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}

		&:hover {
			background-color: darken(#00af53, 5);
		}
	}

	& .section_dialog2 {
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		box-shadow: 0 0 10px 0 rgba($gray4, .5);
		padding: 1.5rem;
		border-radius: .3rem;
		background-color: white;

		& .btn_fechar_form_zap {
			display: flex;
			position: absolute;
			top: -2.2rem;
			right: -2.2rem;
			background-color: white;
			border: solid 2px #00af53;
			padding: .5rem;
			border-radius: 50%;
			width: 2.1rem;
			height: 2.1rem;
			align-items: center;
			justify-content: center;
			transition: all .2s ease-in-out;
			cursor: pointer;

			& i {
				margin: 0;
				padding: 0;
				line-height: initial;
				color: $gray3;
				transition: all .2s ease-in-out;
			}

			&:hover {
				background-color: #00af53;

				& i {
					color: white;
				}
			}
		}

		& .dialog_wpp {
			display: block;
			width: 100%;

			& h2 {
				font-size: .9rem;
				color: $gray1;
				text-align: center;
				margin: 0;
			}

			& .inputs_wpp {
				display: block;
				width: 100%;

				& .inputs_dados {
					display: block;
					width: 100%;

					& input {
						border: solid 1px lighten($gray4, 10);
						border-radius: .3rem;
						padding: .5rem 1rem;
						width: 100%;
					}
				}

				& .input_msg {
					display: block;
					width: 100%;

					& input {
						border: solid 1px lighten($gray4, 10);
						border-radius: .3rem;
						padding: .5rem 1rem;
						width: 100%;
					}

					& button {
						width: 100%;
						display: block;
						background-color: #00af53;
						color: white;
						font-size: .9rem;
						font-weight: 600;
						padding: .5rem 0;
						border: none;
						border-radius: .3rem;
						cursor: pointer;
						transition: all .2s ease-in-out;

						& i {
							font-weight: 400;
						}

						&:hover {
							background-color: darken(#00af53, 5);
						}
					}
				}
			}
		}
	}

	& #form_pag_interna {
		padding: 1rem 0 0 0;
	}

	& .info_valores {
		width: 100%;
		display: block;

		& span {
			font-size: .9rem;
			color: $gray2;
			font-weight: 400;
			margin: 0;
		}

		& .tarja_valor {
			font-size: 2rem;
			color: $colorSecondary;
			font-weight: 600;
			padding-bottom: .5rem;
			border-bottom: solid 1px lighten($gray4, 10);
			margin: 0;
		}

		& .valor_cond {
			width: 100%;
			display: block;
			box-sizing: border-box;

			& .titlecond {
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				margin: 0;
				vertical-align: middle;

				& i {
					font-size: 1.1rem;
					font-weight: 500;
					vertical-align: middle;
					color: $colorSecondary;
					cursor: pointer;
				}
			}

			& p {
				font-size: .8rem;
				font-weight: 400;
				color: lighten($gray2, 5);
				margin: .2rem 0;
			}
		}
	}

	& .btn_entre_em_contato {
		background-color: $colorSecondary;
		text-align: center;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: .3rem 0;
		border-radius: .2rem;
		cursor: pointer;
		transition: all .2s ease-in-out;
		margin: 0;

		&:hover {
			background-color: darken($colorSecondary, 5);
		}
	}

	& .btn_entre_em_contato2 {
		width: fit-content;
		background-color: white;
		text-align: center;
		color: $colorTerciary;
		border: solid 1px lighten($gray4, 10);
		font-size: .8rem;
		font-weight: 600;
		padding: .3rem 1rem;
		border-radius: .2rem;
		cursor: pointer;
		transition: all .2s ease-in-out;
		margin: 0;

		& i {
			color: $colorSecondary;
		}

		&:hover {
			border-color: $gray4;
		}
	}

}

.section_imo_interna {
	display: block;
	width: 100%;
	padding: 1rem;
	border-radius: .3rem;
	box-shadow: 0 3px 5px 0 rgba($gray4, .5);

	& .title {
		margin: 0;
		padding-bottom: .5rem;
		font-size: 1.25rem !important;
		color: $gray1;
		border-bottom: solid 1px lighten($gray4, 10);
	}

	& .texto_descricao_imo {
		display: block;
		width: 100%;

		& h3 {
			color: $gray1;
			font-size: 1rem;
		}

		& h2 {
			color: $gray1;
			font-size: 1rem;
		}

		& p {
			color: $gray2;
			font-size: .8rem;
			font-weight: 400;
			line-height: 1.3rem;
			padding-right: 1rem;
		}

		& .bloco_info_imo {
			display: block;
			width: 100%;

			& ul {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
				gap: 1.5rem 1rem;
				width: 100%;

				& li {
					color: $gray1;
					font-size: .9rem;
					font-weight: 400;
					text-transform: initial;

					& i {
						padding: .1rem;
					}
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {
	.imo_title {
		display: block;
		width: 100%;

		& h1 {
			font-size: 1.1rem;
			font-weight: 500;
			color: $gray1;
			margin: 0;
		}

		& p {
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	.section_imo_interna {
		& .texto_descricao_imo {
			& .bloco_info_imo {
				& ul {
					& li {
						font-size: .8rem;
					}
				}
			}
		}
	}

	.imovel_info_section {
		gap: .5rem;

		& .item_imovel_section {
			& .share_print_block {
				gap: .5rem;
			}
		}
	}
}

/* MOBILE */
@media (max-width: 1023px) {
	.info_valores_mob {
		width: 100%;
		display: block;

		& span {
			font-size: .9rem;
			color: $gray2;
			font-weight: 400;
			margin: 0;
		}

		& .tarja_valor {
			font-size: 1.5rem;
			margin: 0;
			color: $colorSecondary;
			font-weight: 600;
			padding-bottom: .5rem;
			border-bottom: solid 1px lighten($gray4, 10);
		}

		& .valor_cond {
			width: 100%;
			display: block;
			box-sizing: border-box;

			& .titlecond {
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				margin: 0;
				vertical-align: middle;

				& i {
					font-size: 1.1rem;
					font-weight: 500;
					vertical-align: middle;
					color: $colorSecondary;
					cursor: pointer;
				}
			}

			& p {
				font-size: .8rem;
				font-weight: 400;
				color: lighten($gray2, 5);
				margin: .2rem 0;
			}
		}

		& .btn_entre_em_contato {
			background-color: $colorTerciary;
			text-align: center;
			color: white;
			font-size: 1rem;
			font-weight: 600;
			padding: .3rem 0;
			border-radius: 2rem;
			cursor: pointer;
			transition: all .2s ease-in-out;
			margin: 0;

			&:hover {
				background-color: $colorSecondary;
			}
		}
	}

	.section_imo_interna {
		& .texto_descricao_imo {
			& .bloco_info_imo {
				& ul {
					grid-template-columns: repeat(2, 1fr);
					padding: .5rem;

					& li {
						color: $gray1;
						font-size: .9rem;
						font-weight: 400;
					}
				}
			}
		}
	}

	.imo_title {
		& h1 {
			font-size: 1rem;
		}

		& p {
			font-size: .9rem;
		}
	}

	.section_troca_promocao {
		grid-template-columns: 1fr;
		gap: 0;

		.box_promocao_troca {
			margin-bottom: 2rem;
		}
	}

	.imovel_info_section {
		flex-direction: column;
		gap: 1.5rem;
	}

	.formulario_interno {
		& h4 {
			font-size: 1.3rem;
			font-weight: 600;
			color: $gray1;
			margin: 0;
		}
	}

	.texto_descricao_imo {
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}
}