.busca {
	display: block;
	width: 70%;
	text-align: center;
	z-index: 2;
	position: absolute;
	top: 85%;
	left: 50%;
	transform: translate(-50%, -85%);

	& .slogan {
		display: block;
		width: 100%;
	
		& h1{
			font-size: 2rem;
			font-weight: 600;
			color: white;
			text-align: center;
			line-height: 3rem;
			margin: 0;
			text-shadow: 1px 1px 3px black;
		}
	
		& h2{
			font-size: 1.1rem;
			font-weight: 500;
			color: white;
			text-align: center;
			margin: 0;
			text-shadow: 1px 1px 3px black;
		}
	}

	& .busca_inferior{
		display: block;
		width: 100%;
	
		& .bloco_busca{
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: .5rem;
			box-sizing: border-box;

			& .item_busca {
				width: 100%;
				display: block;
				margin: 0;

				& .btn-home{
					padding: .85rem 0;
					border-radius: .3rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorSecondary;
					color: white;
					font-size: .9rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					transition: all .2s ease-in-out;
					
					& i {
						font-weight: 600;
						font-size: .8rem;
					}

					&:hover{
						background-color: darken($colorSecondary, 5);
					}
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .7rem 0;
	border: none;
	border-radius: .2rem;
	background-color: $colorPrimary;
	color: $colorSecondary;
	border: solid 1px $colorPrimary;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .7rem 0;
	border: solid 1px $gray4;
	border-radius: .2rem;
	text-transform: uppercase;
	background-color: white;
	color: $colorTerciary;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: lighten($gray4, 15);
	}
}

.btn_filtros_avancados{
	background-color: $colorPrimary;
	border: solid 1px $colorPrimary;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;

	& p{
		color: $colorSecondary;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
	}
	
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.btn_limpar_filtros{
	background-color: white;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;

	& p{
		color: $gray1;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		transition: all .2s ease-in-out;
		text-transform: uppercase;
		text-align: center;
		margin: 0;

	}
	&:hover{
		background-color: lighten($gray4, 15);
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .45rem 0;
	border: solid 1px $colorSecondary;
	border-radius: .3rem;
	background-color: $colorSecondary;
	color: white;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	&:hover{
		background-color: darken($colorSecondary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .7rem 0;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;
	background-color: white;
	color: $gray2;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		border-color: $gray4;
		background-color: $gray5;
	}
}

#render-busca {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;

	& .miniatura-imovel {
		width: 100% !important;
	}
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: .3rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorSecondary;
			top: 40%;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: lighten($gray5, 2);
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			font-weight: 400;
			color: $gray2 !important;

			& span{
				width: 11px !important;
				height: 11px !important;
				float: none !important;
			}
		}
	}
}

.btns_busca_filtros_mob {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .5rem;
}

.filtros_avancados_e_ordenar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	& .filtros_ord {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
		margin: 0;
	}
}

@media (max-width: 1023px){

	#render-busca {
		grid-template-columns: repeat(1, 1fr);
	
		& .miniatura-imovel {
			width: 100% !important;
		}
	}

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca {
		width: 100%;
		position: inherit;
		top: inherit;
		left: inherit;
		transform: inherit;
	
		& .slogan {
			& h1{
				color: $colorPrimary;
				font-size: 1.5rem;
				line-height: 2.25rem;
				text-shadow: none;
				margin-bottom: .5rem;
			}
		
			& h2{
				color: $colorPrimary;
				font-size: .9rem;
				line-height: 1.5rem;
				text-shadow: none;
			}
		}
	
		& .busca_inferior{
			display: block;
			width: 100%;
		
			& .bloco_busca{
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: .5rem;
				box-sizing: border-box;
	
				& .item_busca {
					width: 100%;
					display: block;
					margin: 0;
	
					& .btn-home{
						padding: .85rem 0;
						border-radius: .3rem;
						width: 100%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorSecondary;
						color: white;
						font-size: .9rem;
						font-weight: 600;
						border: none;
						cursor: pointer;
						transition: all .2s ease-in-out;
						
						& i {
							font-weight: 600;
							font-size: .8rem;
						}
	
						&:hover{
							background-color: darken($colorSecondary, 5);
						}
					}
				}
			}
		}
	}

	.filtros_avancados_e_ordenar {
		flex-direction: column;
		gap: 2rem;
		
		& .filtros_ord {
			width: 100%;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			gap: 0;
			margin: 0;
		}
	}
}

