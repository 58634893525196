.miniatura-imovel {
	display: block;
	position: relative;

	& .miniatura-imovel-img{
		display: block;
		border-radius: .3rem .3rem 0 0;
		width: 100%;
		background-color: $gray5;
		user-select: none;
		border: solid 1px $gray5;
		box-shadow: 0 0 10px 0 rgba($gray2, .1);
		border-bottom: none;
		position: relative;
		overflow: hidden;
		padding-top: 100%;

		& img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		& .miniatura_status{
			position: absolute;
			top: .5rem;
			left: .5rem;
			background-color: rgba(white, .9);
			border: solid 1px lighten($gray4, 10);
			font-weight: 600;
			color: $gray1;
			font-size: .75rem;
			padding: .2rem .5rem;
			border-radius: .2rem;
			margin: 0;
		}
	}

	& .miniatura-imovel-detalhe{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		border: solid 1px $gray5;
		box-shadow: 0 5px 10px 0 rgba($gray2, .1);
		border-radius: 0 0 .3rem .3rem;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura-titulo{
			display: block;
			width: 100%;
			height: 2.5rem;
			overflow: hidden;

			& p{
				text-transform: capitalize;
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;

				& i{
					font-size: .8rem;
					color: $colorSecondary;
					padding: .1rem;
				}
			}
		}
		
		& .acao_cod{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			& p {
				text-transform: capitalize;
				font-size: .8rem;
				font-weight: 400;
				color: $gray1;
				margin: 0;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: .5rem;
			height: 5rem;
			width: 100%;
			overflow: hidden;

			& ul{
				& li{
					font-size: .8rem;
					color: $gray1;
					font-weight: 400;

					& i{
						color: $colorSecondary;
						font-weight: 300;
						font-size: 1rem;
					}
				}
			}
		}

		& .miniatura-preco{
			padding-top: .5rem;
			width: 100% !important;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: .5rem;
	
			& .imo_preco_miniatura{
				padding: .3rem 1rem;
				width: fit-content;
				margin: 0;
				text-align: center;
				font-size: 1.1rem;
				font-weight: 600;
				color: white;
				background-color: $colorSecondary;
				border-radius: .3rem;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorSecondary, 5);
				}
			}
	
			& .btn_vermais_miniatura{
				padding: .45rem 1rem;
				width: fit-content;
				margin: 0;
				text-align: center;
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				background-color: transparent;
				border-radius: .3rem;
				transition: all .2s ease-in-out;
	
				&:hover{
					background-color: lighten($gray4, 15);
				}
			}
		}
	}
}

.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

/* SÓ SE APLICA NO PC */
.iwidth-100{
	width: 100% !important;
}
.iwidth-50{
	width: 50% !important;
}
.iwidth-33-3{
	width: 33.333333% !important;
}
.iwidth-25{
	width: 25% !important;
}
.iwidth-20{
	width: 20% !important;
}



@media (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 2rem !important;
	}
	
	.splide__arrow--next{
		right: 2rem !important;
	}

}

@media (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 0 !important;
	}
	
	.splide__arrow--next{
		right: 0 !important;
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			& .miniatura-titulo{
				height: 2.75rem;
	
				& p{
					font-size: .85rem;
				}
			}
	
			& .miniatura-imovel-btns-icons{
				gap: .25rem;
				height: 6rem;
	
				& ul{
					& li{
						& i{
							font-size: .9rem;
						}
					}
				}
			}
	
			& .miniatura-preco{
				gap: .25rem;
				align-items: stretch;
		
				& .imo_preco_miniatura{
					padding: .3rem 1rem;
					font-size: 1rem;
				}
		
				& .btn_vermais_miniatura{
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0 .45rem;
					font-size: .8rem;
				}
			}
		}
	}
}


@media (max-width: 1023px){
	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			& .miniatura-imovel-btns-icons{
				height: 6rem;
			}
		}
	}
}

